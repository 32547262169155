$(document).ready(function (){
    $(document).on('click', '.tab-nav-control', function(){
        var container = $(this).parent().parent().parent();
        $(container).find('.tab-wrap div').removeClass('active');
        $(container).find('.tab-nav-control').removeClass('active');
        $('#' + $(this).attr('data-tab')).addClass('active');
        $(this).addClass('active');
    });

    jQuery(document).ready(function () {
        jQuery('.add-another-collection-widget').click(function (e) {
            e.preventDefault();
            var list = jQuery(jQuery(this).attr('data-list'));
            // Try to find the counter of the list
            var counter = list.data('widget-counter') | list.children().length;
            // If the counter does not exist, use the length of the list
            if (!counter) { counter = list.children().length; }

            // grab the prototype template
            var newWidget = list.attr('data-prototype');

            // replace the "__name__" used in the id and name of the prototype
            // with a number that's unique to your emails
            // end name attribute looks like name="contact[emails][2]"
            newWidget = newWidget.replace(/__name__/g, counter);
            // Increase the counter
            counter++;
            // And store it, the length cannot be used if deleting widgets is allowed
            list.data(' widget-counter', counter);

            // create a new list element and add it to the list
            var newElem = jQuery(list.attr('data-widget-tags')).html(newWidget);
            newElem.appendTo(list);

        });
    });
});

$(document).ready(function() {

    $('html, body').on('click', '.remove-collection-widgetRemove', function() {
        $(this).closest('li').remove();
        return false;
    });
});

$(document).ready(function() {
    $(document).on('click', '.confirm-delete-button', function () {
        var url = $(this).attr('data-href');
        swal({
            text: "You won't be able to revert this!",
            showCancelButton: true,
            confirmButtonColor: '#bb342f',
            cancelButtonColor: '#aaa',
            confirmButtonText: 'Yes, remove it!'
        }).then(function(result) {
            if (result.value) {
                window.location.replace(url);
            }
        });
    });
});

// Incident Notes script
$(document).ready(function() {
    $(document).on('click', '.comment_trigger', function (event) {
        event.preventDefault();

        var ajax_url = $(this).attr('data-ajax-url');
        $.ajax({
            url: ajax_url,
            success: function (data) {
                $('body').append(data);
                var modal = $('#commentModal').iziModal({
                    padding: '20'
                });
                modal.iziModal('open');

                $(document).on('closed', '#commentModal', function (e) {
                    modal.iziModal('destroy');
                    $('#commentModal').remove();
                });
            }
        });

    });
});
